import React, { useEffect } from "react";
import ScreenHeading from "../../utilities/ScreenHeading/ScreenHeading";
import ScrollService from "../../utilities/ScrollService";
import Animations from "../../utilities/Animations";
import "./AboutMe.css";

export default function AboutMe(props) {
  let fadeInScreenHandler = (screen) => {
    if (screen.fadeInScreen !== props.id) return;
    Animations.animations.fadeInScreen(props.id);
  };
  const fadeInSubscription =
    ScrollService.currentScreenFadeIn.subscribe(fadeInScreenHandler);

  const SCREEN_CONSTSANTS = {
    description:
      "I'm currently a DevOps Engineer. I'm looking for open projects for hours of deliveries for creation or consultation in your environments and services Regarding my profession, I continue as DevOps acting mainly in the CI and CD part of AWS, GCP and Azure environments, orchestrating resources and monitoring environments. Infrastructure creation for applications via IaC terraform using the best modularization concepts for your environment Experience in K8S (Kubernetes) for orchestrating the creation of the environment for your service, in both cloud's described above. Knowledge in developing APIs following standards REST with preferred language on TYPESCRIPT and NODE and among other programming languages and tests to guarantee that the code comes out with the developed performance.",
    highlights: {
      bullets: [
        "Environment scalability",
        "Observability",
        "Automation",
        "Cloud",
        "Agile",
        "IaC",
      ],
      heading: "Here are a Few Highlights:",
    },
  };
  const renderHighlight = () => {
    return SCREEN_CONSTSANTS.highlights.bullets.map((value, i) => (
      <div className="highlight" key={i}>
        <div className="highlight-blob"></div>
        <span>{value}</span>
      </div>
    ));
  };

  return (
    <div
      className="about-me-container screen-container fade-in"
      id={props.id || ""}
    >
      <div className="about-me-parent">
        <ScreenHeading title={"About Me"} subHeading={"Why Choose Me?"} />
        <div className="about-me-card">
          <div className="about-me-profile"></div>
          <div className="about-me-details">
            <span className="about-me-description">
              {SCREEN_CONSTSANTS.description}
            </span>
            <div className="about-me-highlights">
              <div className="highlight-heading">
                <span>{SCREEN_CONSTSANTS.highlights.heading}</span>
              </div>
              {renderHighlight()}
            </div>
            <div className="about-me-options">
              <button
                className="btn primary-btn"
                onClick={() => ScrollService.scrollHandler.scrollToHireMe()}
              >
                {" "}
                Hire Me{" "}
              </button>
              <a href=".pdf" download=".pdf">
                <button className="btn highlighted-btn">Get curriculum</button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
