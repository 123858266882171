import React, { useState, useEffect } from "react";
import ScreenHeading from "../../utilities/ScreenHeading/ScreenHeading";
import ScrollService from "../../utilities/ScrollService";
import Animations from "../../utilities/Animations";
import "./Resume.css";

const Resume = (props) => {
  /* STATES */
  const [selectedBulletIndex, setSelectedBulletIndex] = useState(0);
  const [carousalOffsetStyle, setCarousalOffsetStyle] = useState({});

  let fadeInScreenHandler = (screen) => {
    if (screen.fadeInScreen !== props.id) return;

    Animations.animations.fadeInScreen(props.id);
  };
  const fadeInSubscription =
    ScrollService.currentScreenFadeIn.subscribe(fadeInScreenHandler);

  /* REUSABLE MINOR COMPONENTS */
  const ResumeHeading = (props) => {
    return (
      <div className="resume-heading">
        <div className="resume-main-heading">
          <div className="heading-bullet"></div>
          <span>{props.heading ? props.heading : ""}</span>
          {props.fromDate && props.toDate ? (
            <div className="heading-date">
              {props.fromDate + "-" + props.toDate}
            </div>
          ) : (
            <div></div>
          )}
        </div>
        <div className="resume-sub-heading">
          <span>{props.subHeading ? props.subHeading : ""}</span>
        </div>
        <div className="resume-heading-description">
          <span>{props.description ? props.description : ""}</span>
        </div>
      </div>
    );
  };

  /* STATIC RESUME DATA FOR THE LABELS*/
  const resumeBullets = [
    { label: "Education", logoSrc: "education.svg" },
    { label: "Work History - Ezops", logoSrc: "work-history.svg" },
    { label: "Work History - 2RPnet", logoSrc: "work-history.svg" },
    { label: "Programming Skills", logoSrc: "programming-skills.svg" },
    { label: "Projects", logoSrc: "projects.svg" },
    { label: "Interests", logoSrc: "interests.svg" },
  ];

  //here we have
  const programmingSkillsDetails = [
    { skill: "JavaScript", ratingPercentage: 50 },
    { skill: "Terraform", ratingPercentage: 65 },
    { skill: "Kubernetes", ratingPercentage: 60 },
    { skill: "Docker", ratingPercentage: 65 },
    { skill: "Ansible", ratingPercentage: 50 },
    { skill: "AWS", ratingPercentage: 50 },
    { skill: "Git", ratingPercentage: 80 },
    { skill: "Helm", ratingPercentage: 75 },
    { skill: "Typescript", ratingPercentage: 80 },
  ];

  const projectsDetails = [
    {
      title: "In creation",
      duration: { fromDate: "", toDate: "" },
      description:
        ".",
      subHeading: "",
    },
    // {
    //   title: "Mobile E-shop ",
    //   duration: { fromDate: "2020", toDate: "2021" },
    //   description:
    //     "An ecommerce application designed to sell products online wth payment system integration",
    //   subHeading:
    //     "Technologies Used:  React Native, Mongo DB, Express Js, Node Js, Redux.",
    // },
    // {
    //   title: "Ecommerce Website ",
    //   duration: { fromDate: "2020", toDate: "2021" },
    //   description:
    //     "Online ecommerce website for showcasing and selling products onlne with payment system integration, both Paypal and Stripe",
    //   subHeading:
    //     "Technologies Used: Mongo DB, Epress Js, React Js, Node JS, Redux, Bootstrap.",
    // },
  ];

  const resumeDetails = [
    <div className="resume-screen-container" key="education">
      <ResumeHeading
        heading={"Universidade Paulista"}
        subHeading={"Bachelor - Computer Science"}
        fromDate={"2020"}
        toDate={"Present"}
      />

      <ResumeHeading
        heading={
          "Federal Institute of Education, Science and Technology - IFSP"
        }
        subHeading={"technician - Industrial mechanic technician"}
        fromDate={"2016"}
        toDate={"2019"}
      />
    </div>,

    /* WORK EXPERIENCE */
    <div className="resume-screen-container" key="work-experience-1">
      <div className="experience-container">
        <ResumeHeading
          heading={"Ezops Cloud"}
          subHeading={"Devops Engineer"}
          fromDate={"2022"}
          toDate={"Present"}
        />
        <div className="experience-description">
          <span className="resume-description-text">I currently work as an Infrastructure Engineer (DevOps) at EZOps.</span>
        </div>
        <div className="experience-description">
          <span className="resume-description-text">- Creation of infrastructure for services.</span>
          <br />
          <span className="resume-description-text">- Service Automation | CICD. </span>
          <br />
          <span className="resume-description-text">- Monitoring && Scalability.</span>
          <br />
          <span className="resume-description-text">- AWS && GCP && Azure.</span>
          <br />
        </div>
      </div>
    </div>,
    <div className="resume-screen-container" key="work-experience-2">
      <div className="experience-container">
        <ResumeHeading
          heading={"2RPNet"}
          subHeading={"DevOps Developer - Internship"}
          fromDate={"2021"}
          toDate={"2022"}
        />
        <div className="experience-description">
          <span className="resume-description-text">Intern Experience.</span>
        </div>
        <div className="experience-description">
          <span className="resume-description-text">- Monitoring the creation of resources.</span>
          <br />
          <span className="resume-description-text">- Agility processes such as: AzureDevOps, Daily, Planning... </span>
          <br />
          <span className="resume-description-text">- Knowledge of backend and RESTFUL structure.</span>
          <br />
        </div>
      </div>
    </div>,

    /* PROGRAMMING SKILLS */
    <div
      className="resume-screen-container programming-skills-container"
      key="programming-skills"
    >
      {programmingSkillsDetails.map((skill, index) => (
        <div className="skill-parent" key={index}>
          <div className="heading-bullet"></div>
          <span>{skill.skill}</span>
          <div className="skill-percentage">
            <div
              style={{ width: skill.ratingPercentage + "%" }}
              className="active-percentage-bar"
            ></div>
          </div>
        </div>
      ))}
    </div>,

    /* PROJECTS */
    <div className="resume-screen-container" key="projects">
      {projectsDetails.map((projectsDetails, index) => (
        <ResumeHeading
          key={index}
          heading={projectsDetails.title}
          subHeading={projectsDetails.subHeading}
          description={projectsDetails.description}
          fromDate={projectsDetails.duration.fromDate}
          toDate={projectsDetails.duration.toDate}
        />
      ))}
    </div>,

    /* Interests */
    <div className="resume-screen-container" key="interests">
      <ResumeHeading
        heading="Soccer ball"
        description="Twice a week to keep in shape."
      />
      <ResumeHeading
        heading="Cosmos - Universe"
        description="."
      />
      {/* <ResumeHeading
        heading="Competitive Gaming"
        description="I like to challenge my reflexes a lot while competing in football games, pushing the rank and having interactive gaming sessions excites me the most."
      /> */}
    </div>,
  ];

  const handleCarousal = (index) => {
    let offsetHeight = 360;

    let newCarousalOffset = {
      style: { transform: "translateY(" + index * offsetHeight * -1 + "px)" },
    };

    setCarousalOffsetStyle(newCarousalOffset);
    setSelectedBulletIndex(index);
  };

  const getBullets = () => {
    return resumeBullets.map((bullet, index) => (
      <div
        onClick={() => handleCarousal(index)}
        className={
          index === selectedBulletIndex ? "bullet selected-bullet" : "bullet"
        }
        key={index}
      >
        <img
          className="bullet-logo"
          src={require(`../../assets/Resume/${bullet.logoSrc}`).default}
          alt="B"
        />
        <span className="bullet-label">{bullet.label}</span>
      </div>
    ));
  };

  const getResumeScreens = () => {
    return (
      <div
        style={carousalOffsetStyle.style}
        className="resume-details-carousal"
      >
        {resumeDetails.map((ResumeDetail) => ResumeDetail)}
      </div>
    );
  };

  useEffect(() => {
    return () => {
      /* UNSUBSCRIBE THE SUBSCRIPTIONS */
      fadeInSubscription.unsubscribe();
    };
  }, [fadeInSubscription]);

  return (
    <div
      className="resume-container screen-container fade-in"
      id={props.id || ""}
    >
      <div className="resume-content">
        <ScreenHeading title={"Resume"} subHeading={"My formal Bio Details"} />
        <div className="resume-card">
          <div className="resume-bullets">
            <div className="bullet-container">
              <div className="bullet-icons"></div>
              <div className="bullets">{getBullets()}</div>
            </div>
          </div>

          <div className="resume-bullet-details">{getResumeScreens()}</div>
        </div>
      </div>
    </div>
  );
};

export default Resume;
